.BoxSentMSG {
  


  display: flex;

  padding-top: -7px;
  bottom: 26px;
  position: absolute;
  width: 98%;
  align-items: center;
  margin: 0px 3px 0px 3px;
  border-radius: 30px;
}

.langBtn {
  width: fitContent;
  height: 42px;
  margin-right: 5px;
  margin-left: 5px;
  padding: 10px;
  border: none;
  border-radius: 20px;
  font-size: 20px;
  /* background-color: #ededed !important; */
  cursor: pointer;
  transition: background-color 0.3s;
}
.input {
  /* margin: 0px 10px; */
  border: none;
  height: 42px;
  font-size: 18px;
  border: none;
  /* background-color: #ededed; */
  border-radius: 20px;
  width: 100%;
 
  outline: none;
  padding: 0px 11px 0px 12px;
}
.schemesList {
    padding: 5px 10px;
    font-size: 15px;
    text-align: center;
    cursor: pointer;
    max-width: 100%;
    min-width: 50%;
    /* font-weight: 500; */
    /* margin: 0; */
    background-color: green;
    color: white;
    border-radius: 10px;
    margin: 10px;
  
  }