.options-container {
  padding: 10px;
}

.options-box {
  padding: 10px 10px 5px 10px;
  height: fit-content;
  width: 55px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  border-radius: 20px;
  justify-content: center;
  /* text-align: center; */
  margin: 5px;
}

.options-box:hover {
  cursor: pointer;
}

/* Responsive styles */
@media (max-width: 768px) {
  .options-box {
   width: 55px;
    height: 55px;
  }
}

@media (max-width: 576px) {
  .options-box {
    width: 55px;
    height: 55px;
  }
}
